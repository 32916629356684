export const environment = {
  production: true,
  baseUrl: 'https://customerapi-qa.coca-cola.com/api',
  tenant: 'consumerb2ctest',
  URL_MYBAR: 'https://tccc-edge-mba-qa-01.azureedge.net/',
  URL_B2C:'https://customer-login-qa.coca-cola.com',
  clientID: '4576d4c8-5581-4de7-bbcd-60877d682afe',
  signUpSignIn: 'B2C_1A_QA_signup_signin',
  signInWithPhone: 'B2C_1A_QA_SIGNINPHONE',
  signUpSignInMagicLynk: 'B2C_1A_QA_SignUpOrSigninMagicLynk',
  changePassword: 'B2C_1A_QA_ChangePassword',
  editProfile: 'B2C_1_QA_ProfileEdit',
  tokenTraderAuth: "45faf124-8636-4dda-b809-aafd9e85d61a",
  clientID_Mybar:'6a77ebf3-c973-443c-ae66-3a3624cb8a5b',
  aud_token_mybar:"Ea489fc44-3cc0-4a78-92f6-e413cd853eae",
  function_key:"ScB6dacwCKK2nxo7GWQf0HIt399sehB/28wpvFN8Peg="
};
